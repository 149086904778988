<template>
  <div class="content">
    <!--Page Header Start-->
    <!-- <section class="page-header news">
      <div class="page-header-bg"></div>
      <div class="page-header-bg-overly"></div>
      <div class="container">
        <div class="page-header__inner">
          <h2>璐付资讯</h2>
        </div>
      </div>
    </section> -->
    <!--Page Header End-->

    <!--News Details Start-->
    <section class="news-details">
      <div class="container">
        <div class="back-text flex" @click="$router.go(-1)">
          <img src="@/assets/img/news-left.png" />
          <p>返回</p>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="news-details__left">
              <div class="news-details__content">
                <h3 class="news-details__title">智能大数据如何解决安防痛点？</h3>
                <ul class="list-unstyled news-details__meta" style="margin: 20px 0">
                  <li>
                    <a href="javascript:void(0)" style="color: rgba(0, 0, 0, 0.6); font-size: 14px; font-weight: 400"
                      ><i class="far fa-comments"></i> 2022/05/23</a
                    >
                  </li>
                </ul>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">大数据（</span>big
                    data），指无法在一定时间范围内用常规软件工具进行捕捉、管理和处理的数据集合，是需要新处理模式才能具有更强的决策力、洞察发现力和流程优化能力的海量、高增长率和多样化的信息资产。——《百度百科》</span
                  >
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑"
                      >由此可见，大数据是一种资产，一种无形的资产，它不像房屋车辆那样看得见摸得着，让人实实在在称之为</span
                    >“有用”，但它确实也“有用”，至少在安防行业是显而易见。</span
                  >
                </p>
                <p style="text-align: center">
                  <img
                    src="https://juheimgs.oss-cn-beijing.aliyuncs.com/news/201908/5d6382a05bb11.png!thumb"
                    title="5d6382a05bb11.png!thumb"
                    alt="5d6382a05bb11.png!thumb"
                  />
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">在当前网络互连的大环境下，安防行业相关应用</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">依托强大的人工智能</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">可以迅速产生</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">并处理</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">海量的信息数据。</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">将</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">安防行业本身的信息资源</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">整合之后</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">，有助于公安开展治安防控，警情研判和指挥决策。</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    >就目前来看，大数据对安防而言，颇有用处，但若要实现大数据真正的</span
                  ><span style="font-family: 微软雅黑; font-size: 14px">“大”还有几个大难点需要突破。</span>
                </p>
                <p>
                  <strong><span style="font-family: 微软雅黑; font-size: 14px">01数据整合</span></strong>
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑"
                      >不同来源的数据信息，储存于不同的系统之中，将这些数据整合为一体，是安防行业所要做的前期工作。然而行业、部门之间的壁垒却是个极大阻碍因素，即使是公安内部</span
                    ></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">的某些</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑"
                      >数据，各省市之间也不互通，想要集中在一起也不是件容易事。</span
                    ></span
                  >
                </p>
                <p>
                  <strong><span style="font-family: 微软雅黑; font-size: 14px">02数据深挖</span></strong>
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">大量的</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">信息</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">数据</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">整合后进行</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">分析和处理，</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">需要依靠强大的电脑运算和相当成熟的大数据挖掘技术</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">。</span></span
                  ><strong><span style="font-family: 微软雅黑; font-size: 14px">&nbsp;</span></strong>
                </p>
                <p>
                  <strong><span style="font-family: 微软雅黑; font-size: 14px">03时效性问题</span></strong>
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑"
                      >碍于数据信息的分散还有网络传输的速度，对于安防事件的处理还不能实时有效地进行，事件的分析和处理往往是滞后的。不过</span
                    ></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">随着科技的发展，</span></span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">相信未来人工智能和</span
                    >5G的成熟会大大改进数据处理的时效性问题。</span
                  >
                </p>
                <p>
                  <strong><span style="font-family: 微软雅黑; font-size: 14px">04信息安全</span></strong>
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑"
                      >安防行业，特别是公安行业对信息安全有着特别高的要求，这也是造成数据区域隔离的重要原因。在大数据的利用上，如何保护数据信息的安全也是一个大难题。一味的保护信息安全而各自封闭，否则安防大数据分析将会是无本之木。</span
                    ></span
                  >
                </p>
                <p style="text-align: center">
                  <img
                    src="https://juheimgs.oss-cn-beijing.aliyuncs.com/news/201908/5d6382e42adf3.png!thumb"
                    title="5d6382e42adf3.png!thumb"
                    alt="5d6382e42adf3.png!thumb"
                  />
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">「谛听」</span>&nbsp;-
                    大数据公共安全综合解决方案，是聚合科技有机融合大数据、云计算、LBS、AI等前沿技术，打造的一款基于数字围栏的维稳辅助研判产品，旨在助力打造安全、安稳的公共环境。</span
                  >
                </p>
                <p style="text-align: center">
                  <img
                    src="https://juheimgs.oss-cn-beijing.aliyuncs.com/news/201908/5d638301353ca.png!thumb"
                    title="5d638301353ca.png!thumb"
                    alt="5d638301353ca.png!thumb"
                  />
                </p>
                <p><span style="font-family: 微软雅黑; font-size: 14px">● 谛听核心优势</span></p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    >1、不依赖手机APP、GPS、WiFi等条件，只需导入手机号。</span
                  >
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    >2、可基于地图任意位置划设围栏，无需预先布设任何硬件设备。</span
                  >
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    >3、覆盖全域三网手机用户（330+地市级行政区）。</span
                  >
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    >4、基于意图识别算法、机器学习模型的智能化研判分析。</span
                  >
                </p>
                <p style="text-align: center">
                  <img
                    src="https://juheimgs.oss-cn-beijing.aliyuncs.com/news/201908/5d63832286ed8.png!thumb"
                    title="5d63832286ed8.png!thumb"
                    alt="5d63832286ed8.png!thumb"
                  />
                </p>
                <p>
                  <span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑">「谛听」</span>&nbsp;- 大数据公共安全综合解决方案</span
                  ><span style="font-family: 微软雅黑; font-size: 14px">&nbsp;</span
                  ><span style="font-family: 微软雅黑; font-size: 14px"
                    ><span style="font-family: 微软雅黑"
                      >能有效解决追踪不及时、维稳效率低等问题，实现实时预警、精准预测、态势感知，能有效提升学校周边、火车站</span
                    >/汽车站/机场、体育中心、热门商圈、政府驻地等区域的维稳效果。</span
                  >
                </p>
                <p><span style="font-family: 微软雅黑; font-size: 14px">&nbsp;</span></p>
                <!-- <p class="news-details__text-1"></p>
                                <p class="news-details__text-2"></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--News Details End-->
  </div>
</template>
<script>
export default {};
</script>

<style scoped src="../../assets/cmomon/bootstrap-select.min.css"></style>
<style scoped src="../../assets/cmomon/bootstrap.min.css"></style>
<style scoped src="../../assets/cmomon/owl.carousel.min.css"></style>
<style scoped src="../../assets/cmomon/style.css"></style>
<style scoped src="../../assets/cmomon/izeetak-responsive.css"></style>
<style scoped src="../../assets/cmomon/izeetak.css"></style>

<style lang="less" scoped>
img {
  width: 100% !important;
}
.back-text {
  color: rgba(33, 67, 174, 1);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: pointer;
  img {
    max-width: 20px;
    max-height: 20px;
    margin-right: 6px;
  }
}
</style>
