import { render, staticRenderFns } from "./details.vue?vue&type=template&id=4ffc2d20&scoped=true&"
import script from "./details.vue?vue&type=script&lang=js&"
export * from "./details.vue?vue&type=script&lang=js&"
import style0 from "../../assets/cmomon/bootstrap-select.min.css?vue&type=style&index=0&id=4ffc2d20&prod&scoped=true&lang=css&"
import style1 from "../../assets/cmomon/bootstrap.min.css?vue&type=style&index=1&id=4ffc2d20&prod&scoped=true&lang=css&"
import style2 from "../../assets/cmomon/owl.carousel.min.css?vue&type=style&index=2&id=4ffc2d20&prod&scoped=true&lang=css&"
import style3 from "../../assets/cmomon/style.css?vue&type=style&index=3&id=4ffc2d20&prod&scoped=true&lang=css&"
import style4 from "../../assets/cmomon/izeetak-responsive.css?vue&type=style&index=4&id=4ffc2d20&prod&scoped=true&lang=css&"
import style5 from "../../assets/cmomon/izeetak.css?vue&type=style&index=5&id=4ffc2d20&prod&scoped=true&lang=css&"
import style6 from "./details.vue?vue&type=style&index=6&id=4ffc2d20&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffc2d20",
  null
  
)

export default component.exports